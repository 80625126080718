import React from "react";
import styles from "./GuidePage.scss";
import { useState, useEffect } from "react";

const PAGES_LENGTH = 18;
const guideUrls = [];
for (let i = 1; i <= PAGES_LENGTH; i++) {
  const num = i.toString().padStart(2, "0");
  guideUrls.push(import(`../../assets/images/guide/guide-${num}.png`));
}
const promise = Promise.all(guideUrls);

export const GuidePage = () => {
  const [urls, setUrls] = useState([]);

  useEffect(() => {
    promise.then(data => setUrls(data));
  }, []);

  return (
    <div className={styles.guideLayout}>
      {urls.map((url, i) => (
        <img key={`guide-img-${i}`} alt={`guide-img-${i}`} src={url.default}></img>
      ))}
    </div>
  );
};
