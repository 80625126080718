import React from "react";
import styles from "./ExitPage.scss";
import thankYouUrl from "../../assets/images/thank_you.png";

export const ExitPage = () => {
  return (
    <div className={styles.exitLayout}>
      <div className={styles.main}>
        <img alt={"thank you"} src={thankYouUrl}></img>
      </div>{" "}
    </div>
  );
};
