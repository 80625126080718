import React from "react";
import styles from "./PolicyPage.scss";
import configs from "../../utils/configs";

/* eslint-disable @calm/react-intl/missing-formatted-message */
export const PolicyPage = () => {
  return (
    <div className={styles.guideLayout}>
      <p className={styles.title}>プライバシーポリシー</p>
      <p className={styles.maintext}>
        webメタバース（当サービス）では、メタバース上で提供するサービス（以下本サービス）で取得したプライバシー情報の取扱いに関し、以下のとおりプライバシーポリシー（以下「本ポリシー」）を定めます。
      </p>
      <p className={styles.subtitle}>個人情報の利用目的</p>
      <p className={styles.subtext}>
        当サービスでは、メールでのお問い合わせやコメントの際に、お名前（ハンドルネーム）・メールアドレス等の個人情報をご登録いただく場合があります。
        これらの個人情報は、質問に対する回答や必要な情報をご連絡するために利用し、それ以外の目的では利用しません。
      </p>
      <p className={styles.subtitle}>個人情報の第三者への開示</p>
      <p className={styles.subtext}>
        個人情報は適切に管理し、以下に該当する場合を除いて第三者に開示することはありません。
        <li>本人のご了解がある場合</li>
        <li>法令等への協力のため、開示が必要となる場合</li>
      </p>
      <p className={styles.subtitle}>個人情報の開示・訂正・追加・削除・利用停止</p>
      <p className={styles.subtext}>
        個人情報の開示・訂正・追加・削除・利用停止をご希望の場合には、ご本人であることを確認したうえで、速やかに対応致します。
      </p>
      <p className={styles.subtitle}>Cookieについて</p>
      <p className={styles.subtext}>
        当サービスでは、一部のコンテンツにおいてCookieを利用しています。
        Cookieとは、webコンテンツへのアクセスに関する情報であり、お名前・メールアドレス・住所・電話番号は含まれません。
        また、お使いのブラウザ設定からCookieを無効にすることが可能です。
      </p>
      <p className={styles.subtitle}>アクセス解析ツールについて</p>
      <p className={styles.subtext}>
        当サービスでは、Googleによるアクセス解析ツール「Googleアナリティクス」を利用しています。
        このGoogleアナリティクスはトラフィックデータの収集のためにCookieを使用しています。
        このトラフィックデータは匿名で収集されており、個人を特定するものではありません。
        この機能はCookieを無効にすることで収集を拒否することが出来ますので、お使いのブラウザの設定をご確認ください。
      </p>
      <p className={styles.subtitle}>著作権について</p>
      <p className={styles.subtext}>
        当サービスで掲載している画像の著作権・肖像権等は各権利所有者に帰属します。権利を侵害する目的ではありません。
        記事の内容や掲載画像等に問題がある場合、各権利所有者様本人が直接メールでご連絡下さい。本人確認後、対応致します。
        また、当サービスのコンテンツ（記事・画像・その他プログラム）について、許可なく転載することを禁じます。
        引用の際は、当サービスへのリンクを掲載するとともに、転載であることを明記してください。
      </p>
      <p className={styles.subtitle}>免責事項</p>
      <p className={styles.subtext}>
        当サービスからリンクやバナーなどによって他のサイトに移動した場合、移動先サイトで提供される情報、サービス等について一切の責任を負いません。
        当サービスのコンテンツについて、可能な限り正確な情報を掲載するよう努めていますが、誤情報が入り込んだり、情報が古くなっている場合があります。
        当サービスに掲載された内容によって生じた損害等の一切の責任を負いかねますのでご了承ください。
      </p>
      <p className={styles.subtitle}>お問い合わせ</p>
      <p className={styles.subtext}>
        本ポリシーに関するお問い合わせは，{configs.translation("contact-email")}までお願いいたします。
      </p>
    </div>
  );
};
