import React from "react";
import ReactDOM from "react-dom";
import { WrappedIntlProvider } from "./react-components/wrapped-intl-provider";
import "./utils/theme";
import { HomePage } from "./react-components/home/HomePage";
import { PolicyPage } from "./react-components/policy/PolicyPage";
import { ExitPage } from "./react-components/exit/ExitPage";
import { GuidePage } from "./react-components/guide/GuidePage";
import { AuthContextProvider } from "./react-components/auth/AuthContext";
import "./react-components/styles/global.scss";
import { ThemeProvider } from "./react-components/styles/theme";
import { store } from "./utils/store-instance";
import { useLocation, BrowserRouter } from "react-router-dom";
import queryString from "query-string";
// import { Base } from "./react-components/room/LoadingScreen.stories";
// import { Base } from "./react-components/room/RoomEntryModal.stories";

window.APP = { store };

const MainContent = page => {
  // if (page == "test") return <Base />;
  if (page == "policy") return <PolicyPage />;
  if (page == "exit") return <ExitPage />;
  if (page == "guide") return <GuidePage />;
  return <HomePage />;
};

function Root() {
  const location = useLocation();
  const params = queryString.parse(location.search);
  const page = params.page;
  return (
    <WrappedIntlProvider>
      <ThemeProvider store={store}>
        <AuthContextProvider store={store}>{MainContent(page)}</AuthContextProvider>
      </ThemeProvider>
    </WrappedIntlProvider>
  );
}

ReactDOM.render(
  <BrowserRouter>
    <Root />
  </BrowserRouter>,
  document.getElementById("home-root")
);
